exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blogs-aditya-l-1-jsx": () => import("./../../../src/pages/blogs/aditya_l1.jsx" /* webpackChunkName: "component---src-pages-blogs-aditya-l-1-jsx" */),
  "component---src-pages-blogs-gravit-waves-jsx": () => import("./../../../src/pages/blogs/gravit_waves.jsx" /* webpackChunkName: "component---src-pages-blogs-gravit-waves-jsx" */),
  "component---src-pages-blogs-how-a-touch-displays-works-jsx": () => import("./../../../src/pages/blogs/how-a-touch-displays-works.jsx" /* webpackChunkName: "component---src-pages-blogs-how-a-touch-displays-works-jsx" */),
  "component---src-pages-blogs-jsx": () => import("./../../../src/pages/blogs.jsx" /* webpackChunkName: "component---src-pages-blogs-jsx" */),
  "component---src-pages-blogs-microbial-dna-jsx": () => import("./../../../src/pages/blogs/MicrobialDNA.jsx" /* webpackChunkName: "component---src-pages-blogs-microbial-dna-jsx" */),
  "component---src-pages-blogs-pegasus-jsx": () => import("./../../../src/pages/blogs/pegasus.jsx" /* webpackChunkName: "component---src-pages-blogs-pegasus-jsx" */),
  "component---src-pages-blogs-vr-150-jsx": () => import("./../../../src/pages/blogs/vr-150.jsx" /* webpackChunkName: "component---src-pages-blogs-vr-150-jsx" */),
  "component---src-pages-blogs-wave-energy-jsx": () => import("./../../../src/pages/blogs/wave-energy.jsx" /* webpackChunkName: "component---src-pages-blogs-wave-energy-jsx" */),
  "component---src-pages-chapters-jsx": () => import("./../../../src/pages/chapters.jsx" /* webpackChunkName: "component---src-pages-chapters-jsx" */),
  "component---src-pages-e-agm-23-jsx": () => import("./../../../src/pages/e/agm23.jsx" /* webpackChunkName: "component---src-pages-e-agm-23-jsx" */),
  "component---src-pages-e-enigma-jsx": () => import("./../../../src/pages/e/enigma.jsx" /* webpackChunkName: "component---src-pages-e-enigma-jsx" */),
  "component---src-pages-e-inauguration-jsx": () => import("./../../../src/pages/e/inauguration.jsx" /* webpackChunkName: "component---src-pages-e-inauguration-jsx" */),
  "component---src-pages-e-profecia-jsx": () => import("./../../../src/pages/e/profecia.jsx" /* webpackChunkName: "component---src-pages-e-profecia-jsx" */),
  "component---src-pages-e-recon-jsx": () => import("./../../../src/pages/e/recon.jsx" /* webpackChunkName: "component---src-pages-e-recon-jsx" */),
  "component---src-pages-e-techsprint-jsx": () => import("./../../../src/pages/e/techsprint.jsx" /* webpackChunkName: "component---src-pages-e-techsprint-jsx" */),
  "component---src-pages-e-vision-quest-jsx": () => import("./../../../src/pages/e/visionQuest.jsx" /* webpackChunkName: "component---src-pages-e-vision-quest-jsx" */),
  "component---src-pages-e-webinar-jsx": () => import("./../../../src/pages/e/webinar.jsx" /* webpackChunkName: "component---src-pages-e-webinar-jsx" */),
  "component---src-pages-execom-jsx": () => import("./../../../src/pages/execom.jsx" /* webpackChunkName: "component---src-pages-execom-jsx" */),
  "component---src-pages-half-year-registration-jsx": () => import("./../../../src/pages/half-year-registration.jsx" /* webpackChunkName: "component---src-pages-half-year-registration-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-scholorships-jsx": () => import("./../../../src/pages/scholorships.jsx" /* webpackChunkName: "component---src-pages-scholorships-jsx" */),
  "component---src-pages-team-component-jsx": () => import("./../../../src/pages/team.component.jsx" /* webpackChunkName: "component---src-pages-team-component-jsx" */)
}

