module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-52SLBZRN2B"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ieee.gectcr.ac.in"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"IEEE Website GEC Thrissur","short_name":"IEEE GECT","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icons":[{"src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"any maskable"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
